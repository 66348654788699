.mainTextBox{
    margin: auto;
    min-width: 50vw;
    width: auto;
    display: flex;
    flex-direction: column;
}

.profileImg{
    width: 25vw;
    height: auto;
    margin: auto;
}

a{
    color: #88c0d0
}

a:hover{
    color: #242487;
}

@media only screen and (max-width: 450px){
    .gridContainer{
        grid-template-columns: 1fr;
    }
    .profileImg{
        width: 50vw;
    }
    .mainTextBox{
        width: 85vw;
    }
}

@media only screen and (min-width: 450px){

    .gridContainer{
        display: grid;
        grid-template-columns: .5fr 1fr;
        grid-gap: 1vw;
        margin: 1vw;
    }
}