.background{
  background-color: #272727;
  min-height: 100%;
  width: 100%;
  background-size: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  color: white;
}